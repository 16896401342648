.heading
{
    display: flex;
}

.deleteButton
{
    margin-left: auto;
    margin-right: 0;
}

.alignCenter
{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}