@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
    background-color: rgb(244, 244, 244) !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.w-72
{
    width: 14rem;
}


.md\:ml-72  {
    margin-left: 14rem !important;
}

.ant-image-preview-img
{
transform: translate(50%, -50%) !important

}

/* .ant-modal, .ant-modal-content {
    height: 100vh !important;
    width: 100vw !important;
    margin: 0 !important;
    top: 0 !important;
   }
   .ant-modal-body {
    height: calc(100vh - 110px) !important;
   } */