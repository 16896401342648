.login-form {
    max-width: 500px;
    position: fixed;
    top: 45%;
    left: 50%;
    width: 30em;
    height: 22rem;
    margin-top: -9em;
    margin-left: -15em;
    border: 1px solid #ccc;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    padding: 50px 30px 70px 30px;
  }
  .login-form-forgot {
    float: right;
  }
  .login-form-button {
    width: 100%;
    background: #334BD0;
  }

  .LoginInput
  {
    height: 40px;
  }

  .body
  {
    background-color: rgb(244, 244, 244);
  }


 