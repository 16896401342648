/* @media only screen and (min-device-width: 768px) {
    .secondInput
    {
        width: 25rem;
    }
  }


 .secondInput{
    width: 100%
 }  */

.previewModal .ant-modal {
    height: 100vh !important;
    width: 100vw !important;
    margin: 0 !important;
    top: 0 !important;
   }

.previewModal  .ant-modal-content
{
    height: 100vh !important;
    width: 100vw !important;
    margin: 0 !important;
    top: 0 !important;
}

.previewModal  .ant-modal-body {
    height: calc(100vh - 110px) !important;
   }