@media only screen and (min-device-width: 768px) {
    .column {
      flex: 100% !important; /* !important so that it overrides the inline style because by default it has higher priority */
    }

    .stepper
    {

    }
  }


  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: green !important
}

